import SiteButton_ButtonLiftedShadowComponent from '../components/SiteButton/viewer/skinComps/ButtonLiftedShadow/ButtonLiftedShadow.skin';
import SiteButton_ButtonLiftedShadowController from '../components/SiteButton/viewer/SiteButton.controller';


const SiteButton_ButtonLiftedShadow = {
  component: SiteButton_ButtonLiftedShadowComponent,
  controller: SiteButton_ButtonLiftedShadowController
};


export const components = {
  ['SiteButton_ButtonLiftedShadow']: SiteButton_ButtonLiftedShadow
};

